import { gql } from '@apollo/client';

export const OutboundConferenceCallFragment = gql`
  fragment OutboundConferenceCallFields on OutboundConferenceCallQL {
    id
    createdOn
    caller {
      id
      displayName
    }
    conversationId
    transcript {
      id
      events {
        id
        type
      }
      subject
      summary
    }
    outboundCallSid
    hostMdn
    recipientMdn
    recipientDisplayName
    endTime
    startTime
    callStatus
    callDuration
    isBeingRecorded
    hasCallAssist
    callRecording {
      id
      url
    }
  }
`;
