import { createContext, useContext } from 'react';
import { type ApolloClient, ApolloConsumer } from '@apollo/client';

type ApolloClientProviderShape = {
  client: ApolloClient<any> | null | undefined;
};

export const ApolloContext = createContext<ApolloClientProviderShape>({
  client: null,
});

type Props = {};

export const ApolloClientProvider = (props: Props) => (
  <ApolloConsumer>
    {(client: ApolloClient<any>) => (
      <ApolloContext.Provider value={{ client }} {...props} />
    )}
  </ApolloConsumer>
);

export const useApolloClient = () => {
  const { client } = useContext(ApolloContext);
  if (!client) {
    throw Error('Must wrap client in an ApolloClientProvider.');
  }

  return client;
};
