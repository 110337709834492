import { gql } from '@apollo/client';

export const GET_TRANSCRIPT = gql`
  query getTranscript($id: String!) {
    transcript(id: $id) {
      id
      conversationId
      summary
      subject
      events {
        id
        type
        timestamp
        start
        end
        text
        confidence
        speaker
        speakerConfidence
      }
      call {
        ... on PhoneCallQL {
          id
          createdOn
          isActive
          endtime
          startTime
          callDuration
          channelId
          callRecording {
            id
            url
          }
          participant {
            id
            address
            displayName
          }
          channel {
            id
            ... on TwilioChannelQL {
              name
              mdn
            }
          }
        }
        ... on OutboundConferenceCallQL {
          id
          createdOn
          endTime
          startTime
          callDuration
          callRecording {
            id
            url
          }
          caller {
            id
            displayName
          }
          hostMdn
          recipientMdn
          recipientDisplayName
        }
        ... on OutboundProxyCallQL {
          id
          createdOn
          endtime
          startTime
          callDuration
          callRecording {
            id
            url
          }
          caller {
            id
            displayName
          }
          recipientMdn
        }
      }
    }
  }
`;
