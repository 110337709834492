/* eslint-disable no-restricted-syntax */
import { PaletteMode, alpha, lighten } from '@mui/material';

const legacyPalette = {
  offWhite: '#eee',
  lightGray: '#ddd',
  gray: '#999',
  darkGray: '#333',
  crayolaBlue: '#2176ff', // #0984FF
  wageningenGreen: '#2ba84a',
  munsell: '#f3cb00',
  romanSilver: '#85879c',
  chineseRed: '#a03d25',
  navajoWhite: '#ffe3ad',
  dartmouthGreen: '#00853f',
  springBud: '#cce79c',
  orange: '#ffa800',
  aliceBlue: '#E8F3FE',
  bleuDeFrance: '#3A82F7',
  ghostWhite: '#FAFAFC',
  taupeGray: '#888888', // #737376
  slateBlue: '#5C6AC4',
};

const white = '#FFF';
const black = '#000';

export const createPalette = (
  mode: PaletteMode = 'light',
  useNewPrimaryColor?: boolean,
) => {
  return {
    mode,
    common: {
      black,
      white,
      ...legacyPalette,
    },
    primary:
      mode === 'light' && !useNewPrimaryColor
        ? {
            main: '#050712',
            light: '#33343C',
            dark: '#000',
            contrastText: alpha(white, 0.9),
            background: alpha('#050712', 0.07),
          }
        : {
            main: '#4240D1',
            light: '#6766DA',
            dark: '#2E2C92',
            contrastText: alpha(white, 0.9),
            background: alpha('#4240D1', 0.07),
          },
    secondary:
      mode === 'light'
        ? {
            main: '#4536A1',
            light: '#6A5EB3',
            dark: '#302570',
            contrastText: alpha(white, 0.9),
            background: alpha('#4536A1', 0.07),
          }
        : {
            main: '#6A5EB3',
            light: '#4F4DA1',
            dark: '#181760',
            contrastText: alpha(white, 0.9),
            background: alpha('#23218A', 0.25),
          },
    /*
     The following colors should match to the error color:
     #FF0000, #C63A3A, #A03D25, chineseRed (main), #EED5CF (background),
     any color that should conveys a failure or an error, or anything red
    */
    error:
      mode === 'light'
        ? {
            main: '#B3332B',
            light: '#C25B55',
            dark: '#7D231E',
            contrastText: alpha(white, 0.9),
            background: alpha('#B3332B', 0.07),
          }
        : {
            main: '#B3332B',
            light: '#C25B55',
            dark: '#7D231E',
            contrastText: alpha(black, 0.9),
            background: alpha('#B3332B', 0.07),
          },
    /*
     The following colors should match to the error color:
     #ECD9D3 (background), any other color that should conveys a warning
    */
    warning:
      mode === 'light'
        ? {
            main: '#B35A2B',
            light: '#C27B55',
            dark: '#7D3E1E',
            contrastText: alpha(white, 0.9),
            background: alpha('#B35A2B', 0.07),
          }
        : {
            main: '#B35A2B',
            light: '#C27B55',
            dark: '#7D3E1E',
            contrastText: alpha(black, 0.9),
            background: alpha('#B35A2B', 0.07),
          },
    /*
     The following colors should match to the success color:
     #00853E, #058D4A (main), #E6F3EC (background), any other color that should conveys success,
     or anything green
    */
    success:
      mode === 'light'
        ? {
            main: '#3DA137',
            light: '#63B35F',
            dark: '#2A7026',
            contrastText: alpha(white, 0.9),
            background: alpha('#3DA137', 0.07),
          }
        : {
            main: '#3DA137',
            light: '#63B35F',
            dark: '#2A7026',
            contrastText: alpha(black, 0.9),
            background: alpha('#3DA137', 0.07),
          },
    info:
      mode === 'light'
        ? {
            main: '#2B7EB3',
            light: '#5597C2',
            dark: '#1E587D',
            contrastText: alpha(white, 0.9),
            background: alpha('#2B7EB3', 0.07),
          }
        : {
            main: '#2B7EB3',
            light: '#5597C2',
            dark: '#1E587D',
            contrastText: alpha(black, 0.9),
            background: alpha('#2B7EB3', 0.07),
          },
    numa:
      mode === 'light'
        ? {
            main: '#4536A1',
            light: '#6A5EB3',
            dark: '#302570',
            contrastText: alpha(white, 0.9),
            background: alpha('#4536A1', 0.07),
          }
        : {
            main: '#4536A1',
            light: '#6A5EB3',
            dark: '#302570',
            contrastText: alpha(white, 0.9),
            background: alpha('#4536A1', 0.07),
          },
    customer:
      mode === 'light'
        ? {
            main: '#000000',
            light: '#333333',
            dark: '#000000',
            contrastText: alpha(white, 0.9),
            background: alpha('#000000', 0.07),
          }
        : {
            main: '#FFFFFF',
            light: '#CCCCCC',
            dark: '#666666',
            contrastText: alpha(black, 0.9),
            background: alpha('#FFFFFF', 0.07),
          },
    user:
      mode === 'light'
        ? {
            main: '#226CB3',
            light: '#4E89C2',
            dark: '#174B7D',
            contrastText: alpha(white, 0.9),
            background: alpha(white, 0.07),
          }
        : {
            main: '#226CB3',
            light: '#4E89C2',
            dark: '#174B7D',
            contrastText: alpha(white, 0.9),
            background: alpha(white, 0.07),
          },
    appointment:
      mode === 'light'
        ? {
            main: '#32BAB9',
            light: '#5BC7C7',
            dark: '#238281',
            contrastText: alpha(white, 0.9),
            background: alpha('#32BAB9', 0.07),
          }
        : {
            main: '#32BAB9',
            light: '#5BC7C7',
            dark: '#238281',
            contrastText: alpha(black, 0.9),
            background: alpha('#32BAB9', 0.07),
          },
    order:
      mode === 'light'
        ? {
            main: '#7875C9',
            light: '#6A5EB3',
            dark: '#302570',
            contrastText: alpha(white, 0.9),
            background: alpha('#7875C9', 0.07),
          }
        : {
            main: '#7875C9',
            light: '#6A5EB3',
            dark: '#302570',
            contrastText: alpha(white, 0.9),
            background: alpha('#7875C9', 0.07),
          },
    note:
      mode === 'light'
        ? {
            main: '#BAA432',
            light: '#C7B65B',
            dark: '#827223',
            contrastText: alpha(white, 0.9),
            background: alpha('#BAA432', 0.07),
          }
        : {
            main: '#BAA432',
            light: '#C7B65B',
            dark: '#827223',
            contrastText: alpha(black, 0.9),
            background: alpha('#BAA432', 0.07),
          },
    /*
     The following colors should match to the success color:
     #003EA5, #2368AB (main),any other color that should conveys the concept of a task,
    */
    task:
      mode === 'light'
        ? {
            main: '#2B53B3',
            light: '#5575C2',
            dark: '#1E3A7D',
            contrastText: alpha(white, 0.9),
            background: alpha('#2B53B3', 0.07),
          }
        : {
            main: '#2B53B3',
            light: '#5575C2',
            dark: '#1E3A7D',
            contrastText: alpha(white, 0.9),
            background: alpha('#2B53B3', 0.07),
          },
    phonecall:
      mode === 'light'
        ? {
            main: '#2B70B3',
            light: '#558CC2',
            dark: '#1E4E7D',
            contrastText: alpha(white, 0.9),
            background: alpha('#2B70B3', 0.07),
          }
        : {
            main: '#2B70B3',
            light: '#558CC2',
            dark: '#1E4E7D',
            contrastText: alpha(white, 0.9),
            background: alpha('#2B70B3', 0.07),
          },
    payment:
      mode === 'light'
        ? {
            main: '#064A22',
            light: '#376E4E',
            dark: '#043317',
            contrastText: alpha(white, 0.9),
            background: alpha('#064A22', 0.07),
          }
        : {
            main: '#064A22',
            light: '#376E4E',
            dark: '#043317',
            contrastText: alpha(white, 0.9),
            background: alpha('#064A22', 0.07),
          },
    report:
      mode === 'light'
        ? {
            main: '#B35A2B',
            light: '#C27B55',
            dark: '#7D3E1E',
            contrastText: alpha(white, 0.9),
            background: alpha('#B35A2B', 0.07),
          }
        : {
            main: '#B35A2B',
            light: '#C27B55',
            dark: '#7D3E1E',
            contrastText: alpha(black, 0.9),
            background: alpha('#B35A2B', 0.07),
          },
    inbox:
      mode === 'light'
        ? {
            main: '#2B7EB3',
            light: '#5597C2',
            dark: '#1E587D',
            contrastText: alpha(white, 0.9),
            background: alpha('#2B7EB3', 0.07),
          }
        : {
            main: '#2B7EB3',
            light: '#5597C2',
            dark: '#1E587D',
            contrastText: alpha(black, 0.9),
            background: alpha('#2B7EB3', 0.07),
          },
    divider: alpha(mode === 'light' ? black : white, 0.1),
    background: {
      default: mode === 'light' ? white : '#0F0E1B',
      // paper: mode === 'light' ? darken(white, 0.1) : '#21232D',
      paper: mode === 'light' ? white : '#0F0E1B',
      brand: '#2D2BB3',
    },
    text: {
      primary: mode === 'light' ? lighten(black, 0.1) : white,
      secondary: mode === 'light' ? '#08064A' : '#9D9AE3',
      disabled: alpha(mode === 'light' ? black : white, 0.25),
      hint: alpha(mode === 'light' ? black : white, 0.7),
    },
  };
};

declare module '@mui/material/styles/createPalette' {
  interface CommonColors {
    offWhite: string;
    lightGray: string;
    gray: string;
    doveGray: string;
    darkGray: string;
    crayolaBlue: string;
    wageningenGreen: string;
    munsell: string;
    romanSilver: string;
    chineseRed: string;
    navajoWhite: string;
    dartmouthGreen: string;
    springBud: string;
    orange: string;
    aliceBlue: string;
    bleuDeFrance: string;
    ghostWhite: string;
    taupeGray: string;
    slateBlue: string;
  }
  interface TypeText {
    hint: string;
  }
}
declare module '@mui/material/styles' {
  interface SimplePaletteColorOptions {
    background: string;
  }
  interface PaletteColor {
    background: string;
  }
  interface Palette {
    report: Palette['primary'];
    inbox: Palette['primary'];
    numa: Palette['primary'];
    customer: Palette['primary'];
    user: Palette['primary'];
    appointment: Palette['primary'];
    order: Palette['primary'];
    note: Palette['primary'];
    task: Palette['primary'];
    phonecall: Palette['primary'];
    payment: Palette['primary'];
  }
  interface PaletteOptions {
    report?: PaletteOptions['primary'];
    inbox?: PaletteOptions['primary'];
    numa?: PaletteOptions['primary'];
    customer?: PaletteOptions['primary'];
    user?: PaletteOptions['primary'];
    appointment?: PaletteOptions['primary'];
    order?: PaletteOptions['primary'];
    note?: PaletteOptions['primary'];
    task?: PaletteOptions['primary'];
    phonecall?: PaletteOptions['primary'];
    payment?: PaletteOptions['primary'];
  }
}
